import { useEffect, useState } from "react";

//https://github.com/vercel/next.js/discussions/17443
export default function useClientSideOnly() {
  const [isClientSide, setIsClientSide] = useState(false);
  useEffect(() => {
    setIsClientSide(true);
  }, []);

  return isClientSide;
}
