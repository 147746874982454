import { ApiDataResponse } from "../models/ApiData";
import { DependencyList } from "react";
import { useAsyncFn } from "react-use";
type FunctionReturningApiDataResponsePromise<T extends any> = (
  ...args: any[]
) => Promise<ApiDataResponse<T>>;

export const useApiDataResponseFn = <T extends any>(
  fn: FunctionReturningApiDataResponsePromise<T>,
  deps?: DependencyList
) => {
  const [state, execute] = useAsyncFn(fn, deps);
  return { data: state.value?.data, error: state.error, state, execute };
};
