import { useCallback, useRef } from "react";

export type RegisterRefCallback = (name: string) => (element: any) => void;

/** Allow to register n ref associated with a key, see useFocusOnError for usage */
export const useRegisterOrderedRef = (): [RegisterRefCallback, Record<string, any>] => {
  const refSet = useRef<Record<string, any>>({});
  const registerRef: RegisterRefCallback = useCallback((key: string) => {
    return (element: any) => {
      refSet.current[key] = element;
    };
  }, []);

  return [registerRef, refSet.current];
};

//TODO: If needed, Use https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition to sort ref by position in the document (?)
