import React, { useContext } from "react";
import { NotLoggedInUser, UserBase } from "../models/User";

export interface AuthenticationData<TUser extends UserBase = UserBase> {
  user: TUser;
}

export const AuthenticationContext = React.createContext<AuthenticationData>({
  user: NotLoggedInUser,
});

export const useAuthenticationContext = (): AuthenticationData => {
  return useContext(AuthenticationContext) as AuthenticationData;
};

export const useUser = () => {
  const { user } = useAuthenticationContext();
  return user;
};
