export function shallowEqual(object1: any, object2: any) {
  if (Object.is(object1, object2)) {
    return true;
  }

  if (typeof object1 !== "object" || !object1 || typeof object2 !== "object" || !object2) {
    return false;
  }

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}
