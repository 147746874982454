import React from "react";
import { FormattedMessage, MessageFormatElement } from "react-intl";

type PrefixedMessageDescriptor = {
  intlId?: string | undefined;
  intlDescription?: string | object;
  intlDefaultMessage?: string | MessageFormatElement[];
};

const withIntlShortcut = <P extends {}>(Component: React.ComponentType<P>) => {
  const ComponentWithIntl = ({
    intlId,
    intlDefaultMessage,
    intlDescription,
    children,
    ...props
  }: P & PrefixedMessageDescriptor & { children?: React.ReactNode }) => {
    if (intlId) {
      return (
        <Component {...(props as P)}>
          <FormattedMessage
            id={intlId}
            description={intlDescription}
            defaultMessage={intlDefaultMessage}
          />
        </Component>
      );
    }

    return <Component {...(props as P)}>{children}</Component>;
  };
  return ComponentWithIntl;
};

export default withIntlShortcut;
