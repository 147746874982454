import { ApiDataResponse } from "../models/ApiData";
import useSWR, { BareFetcher, Fetcher, Key, SWRConfiguration } from "swr";
import { useUser } from "../contexts/Authentication";

type CustomSWRConfiguration<
  Data = any,
  Error = any,
  Fn extends BareFetcher<any> = BareFetcher<any>
> = SWRConfiguration<Data, Error, Fn> & {
  /** The request need to know the current user and should revalide if the user change. */
  dependsOnCurrentUser?: boolean;
};

type UseSWRParam<Data = any, Error = any, SWRKey extends Key = null> =
  | [key: SWRKey]
  | [key: Key, fetcher: Fetcher<Data, SWRKey> | null]
  | [
      key: SWRKey,
      config:
        | CustomSWRConfiguration<Data, Error, Fetcher<Data, SWRKey>>
        | undefined
    ]
  | [key: Key]
  | [key: Key, fetcher: BareFetcher<Data> | null]
  | [
      key: Key,
      config: SWRConfiguration<Data, Error, BareFetcher<Data>> | undefined
    ]
  | [
      key: Key,
      fetcher: BareFetcher<Data> | null,
      config: CustomSWRConfiguration<Data, Error, BareFetcher<Data>> | undefined
    ];

export const useApiDataResponse = <T extends ApiDataResponse<any>>(
  ...args: UseSWRParam
) => {
  const [key, fetcher, options] = normalize(args as any); //TODO: I can't make it work without any. Maybe we should try another way with https://swr.vercel.app/docs/middleware
  return useApiDataResponseNormalized<T>(key, fetcher, options);
};

export const useApiDataResponseImmutable = <T extends ApiDataResponse<any>>(
  ...args: UseSWRParam
) => {
  const [key, fetcher, options] = normalize(args as any);

  options.revalidateIfStale = false;
  options.revalidateOnFocus = false;
  options.revalidateOnReconnect = false;
  return useApiDataResponseNormalized<T>(key, fetcher, options);
};

const useApiDataResponseNormalized = <T extends ApiDataResponse<any>>(
  key: Key,
  fetcher: Fetcher<T> | null,
  config: Partial<CustomSWRConfiguration<T>>
) => {
  const user = useUser();
  const finalKey =
    config.dependsOnCurrentUser && (!user || !user.isLoggedIn) ? null : key;

  const { data, error, mutate, ...swr } = useSWR<T, any>(
    finalKey,
    fetcher,
    config
  );
  const refresh = mutate;
  return { error, data, refresh, mutate, ...swr };
};

//cf. https://github.com/vercel/swr/blob/b02d74378d1685b8943a051866694efb190b8a49/src/utils/normalize-args.ts#L5
const isFunction = (v: any): v is Function => typeof v === "function";
export const normalize = <KeyType = Key, Data = any>(
  args:
    | [KeyType]
    | [KeyType, Fetcher<Data> | null]
    | [KeyType, SWRConfiguration | undefined]
    | [KeyType, Fetcher<Data> | null, SWRConfiguration | undefined]
): [KeyType, Fetcher<Data> | null, Partial<SWRConfiguration<Data>>] => {
  return isFunction(args[1])
    ? [args[0], args[1], args[2] || {}]
    : [args[0], null, (args[1] === null ? args[2] : args[1]) || {}];
};
