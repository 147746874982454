import { FormikConfig, FormikErrors, FormikTouched, FormikValues, useFormik } from "formik";
import { useEffect, useState } from "react";
import { RegisterRefCallback, useRegisterOrderedRef } from "./useRegisterOrderedRef";

export type FormValues = FormikValues;
export type FormConfig<Values extends FormValues = FormValues> = FormikConfig<Values>;

export type FormHook<Values extends FormValues = FormValues> = {
  handleSubmit: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: (k: keyof Values, v: any) => void;
  values: Values;
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
  submitForm: () => Promise<any>;
  validateForm: (values?: Values) => Promise<FormikErrors<Values>>;

  registerInputRef: RegisterRefCallback;
};

export const useForm = <Values extends FormValues = FormValues>(
  options: FormConfig<Values> & { focusOnFirstErrorOnSubmit?: boolean }
) => {
  const form = useFormik<Values>(options);
  const [registerInputRef, refSet] = useRegisterOrderedRef();
  const [shouldCheckForScroll, setShouldCheckForScroll] = useState(false);

  useEffect(() => {
    if (shouldCheckForScroll && Object.keys(form.errors).length > 0) {
      const firstName = Object.keys(form.errors)[0];
      const refFirstInput = refSet[firstName];
      refFirstInput?.scrollIntoView({ block: "center", inline: "nearest" });
      setShouldCheckForScroll(false);
    }
  }, [shouldCheckForScroll, form.errors]);

  const submitForm = async () => {
    if (options.focusOnFirstErrorOnSubmit) {
      setShouldCheckForScroll(true);
    }
    const result = await form.submitForm();
    setShouldCheckForScroll(false);
    return result;
  };

  return { ...form, submitForm, registerInputRef };
};
