import _ from "lodash";
import { useMemo, useRef } from "react";

/** Debounce a function that may change on every render */
export const useFickleDebouncedFn = <T extends (...args: any) => any>(
  func: T,
  waitMs: number
) => {
  const ref = useRef<T>(func);
  ref.current = func;

  return useMemo(
    () => _.debounce((...args: any) => ref.current(...args), waitMs),
    [ref, waitMs]
  );
};

/** Debounce a function */
export const useDebouncedFn = <T extends (...args: any) => any>(
  func: T,
  waitMs: number
) => {
  return useMemo(() => _.debounce(func, waitMs), [func, waitMs]);
};
