import { XTextFieldProps } from "../components/XTextField/XTextField";
import { FormHook, FormValues } from "../hooks/useForm";

export const registerRadioGroup = <Values extends FormValues>(
  form: FormHook<Values>,
  name: keyof Values
) => {
  return {
    name,
    onChange: form.handleChange,
    value: form.values[name] ?? "",
  };
};

export const registerInputBase = <Values extends FormValues>(
  form: FormHook<Values>,
  name: keyof Values
) => {
  return {
    name: name as string,
    onChange: form.handleChange,
    onBlur: form.handleBlur,
    value: form.values[name] ?? "",
    error: Boolean(form.touched[name] && form.errors[name]),
    inputRef: form.registerInputRef(name as string),
  };
};

export const registerCheckbox = <Values extends FormValues>(
  form: FormHook<Values>,
  name: keyof Values,
  value?: any
) => {
  const currentValue = form.values[name];
  const checked = Array.isArray(currentValue)
    ? (currentValue as Array<any>).includes(value)
    : Boolean(currentValue);
  return {
    name,
    value,
    checked,
    onChange: form.handleChange,
  };
};

export const registerTextField = <Values extends FormValues>(
  form: FormHook<Values>,
  name: keyof Values
): XTextFieldProps => {
  return {
    name: name as string,
    onChange: form.handleChange,
    onBlur: form.handleBlur,
    value: form.values[name] ?? "",
    error: Boolean(form.touched[name] && form.errors[name]),
    inputRef: form.registerInputRef(name as string),
  };
};
