export type EventName = string;
export type EventCallbackArgs = any;
export type EventCallback = (a: EventCallbackArgs) => void;

const eventManager = (() => {
  const subscribes = new Map<EventName, EventCallback[]>();

  const on = (eventName: EventName, callback: EventCallback) => {
    if (!subscribes.has(eventName)) {
      subscribes.set(eventName, []);
    }
    subscribes.get(eventName)!.push(callback);
  };

  const off = (eventName: EventName, callback: EventCallback) => {
    if (subscribes.has(eventName)) {
      if (callback) {
        const cb = subscribes.get(eventName)!.filter((cb) => cb !== callback);
        subscribes.set(eventName, cb);
      }
      subscribes.delete(eventName);
    }

    return this;
  };

  const emit = (eventName: EventName, args?: EventCallbackArgs) => {
    if (subscribes.has(eventName)) {
      subscribes
        .get(eventName)!
        .forEach((callback: EventCallback) => callback(args));
    }
  };

  return {
    on,
    off,
    emit,
  };
})();

export default eventManager;
